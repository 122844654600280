import React from "react";
import './styles.scss';
import Marquee from "react-fast-marquee";
import ThankYou from "../../components/Forms/BASLForm/ThankYou";
// import BusinessSalesTrainingProgram from "./index"

const ThankYouPage = (props) => {

  return (
    <div className="thank-youPage-wrapper" >
      <Marquee
        gradient={false}
        speed={40}
        pauseOnHover={true}
        className="marquee-container-header"
        loop={0}
        style={{zIndex:1000}}
      >
        <i className="marquee-item">
          {" "}
          * Applications for Batch of 2024 are closed now *{" "}
        </i>{" "}
        &nbsp;
        <i> Early Applications open for Batch of 2025 * </i>
      </Marquee>
      <ThankYou
        // search={location?.search}
        formfields={props?.location?.state?.formFields}
        id={props?.location?.state?.id}
        // campaignId={location?.state?.campaignId}
      
      />
    </div>
  );
};

export default ThankYouPage;
